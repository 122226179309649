<template>
    <div>
        <taskCommon
            title="民居建设"
            :currentTree="currentTree"
            :list="list"
            :snSearch="snSearch"
            :listConfig="listConfig"
            :defaultProps="defaultProps"
            widthValue="280px"
            @handleChange="handleChange"
        >
            <slot>
                <Mymap
                    :mapData="mapData"
                    class="map"
                    ref="myMap"
                    @initmap="initMap"
                ></Mymap>
                <Dialog
                    ref="dialog"
                    :dialogData.sync="dialogData"
                    :ycysMc="ycysMc"
                    @closeDisesaseDialog="closeDisesaseDialog"
                    @saveSuccess="saveSuccess"
                >
                </Dialog>
            </slot>
        </taskCommon>
    </div>
</template>
<script>
import taskCommon from "../task-common";
import mixins from "../mixins";
import Dialog from "./dialog";
import { mapActions } from "vuex";
export default {
    name: "",
    components: {
        taskCommon,
        Dialog,
    },
    mixins: [mixins],
    data() {
        return {
            mapData: {
                dxtState: true, //地形图
                maxZoom: 20,
                zoom: 12.5,
                areaState: true,
            },
            currentId: 90502,
            list: [],
            listConfig: {
                name: "XJXMBH",
                value: "ID",
            },
            basicMap: null,
            ycysMc: "",
            selectedId: "",
            selectedIndex: 0,
            areaLayers: null,
            defaultProps: {
                children: "child",
                label: "XMMC",
            },
        };
    },
    watch: {
        keyword: {
            immediate: true,
            handler() {
                this.getBhgcList();
            },
        },
    },
    mounted() {
        this.dialogData.title = "新建民居建设配置";
    },
    methods: {
        ...mapActions(["getNewProjectList"]),
        handleClose() {},
        // 获得新建项目项目列表
        async getBhgcList() {
            let res = await this.getNewProjectList({
                ItemCode: this.currentId,
                keyWord: this.keyword,
            });
            this.list = res;
            this.drawMarkerList();
        },
        drawMarkerList() {
            this.areaLayers.clearLayers();
            this.list.forEach((zone, index) => {
                if (!zone.XMWZ) return false;
                let geoJson = JSON.parse(zone.XMWZ);
                let layer = L.geoJSON(geoJson, {
                    onEachFeature: (feature, layer) => {
                        if (layer.feature.geometry.type === "Point") {
                            let icon = L.icon({
                                iconUrl: require("@image/monitoring_cloud/mjjs.png"),
                                iconSize: [30, 30],
                                iconAnchor: [15, 15],
                            });
                            layer.setIcon(icon);
                        }
                        layer.selfId = zone.ID;
                        layer.selfType = "polygon";
                        layer.QYMC = zone.XJXMBH;
                        this.setFeatureEvents(layer, zone, index);
                        this.areaLayers.addLayer(layer);
                        layer.bindPopup(zone.XJXMBH, {
                            closeButton: false,
                            closeOnClick: true,
                        });
                    },
                    style(feature) {
                        return {
                            color: "#1862ad",
                            fillColor: "#1862ad",
                            weight: 2,
                        };
                    },
                });
                if (
                    this.$route &&
                    this.$route.params &&
                    this.$route.params.data &&
                    this.$route.params.data.jcdxid &&
                    this.$route.params.data.jcdxid === zone.ID
                ) {
                    this.basicMap.fitBounds(layer.getBounds());
                    let latlng = layer.getBounds().getCenter();
                    L.popup({
                        closeOnClick: false,
                        closeButton: false,
                        offset: [0, 5],
                        minWidth: 12,
                    })
                        .setLatLng(latlng)
                        .setContent(zone.XMMC)
                        .openOn(this.basicMap);
                }
            });
        },
        setFeatureEvents(feature, zone, index) {
            // 巡查区的事件
            let _this = this;
            feature.on("click", function (e) {
                // eslint-disable-next-line
                L.DomEvent.stopPropagation(e);
                _this.changeList([
                    feature.selfId,
                    feature.QYMC,
                    index,
                    0,
                    zone,
                ]);
            });
            feature.on("mouseover", function (e) {
                this.openPopup();
            });
            feature.on("mouseout", function (e) {
                this.closePopup();
            });
        },
        async changeList(val) {
            // 点击遗产要素列表，直接显示弹出框
            this.currentTree = val[0];
            this.ycysMc = val[1];
            this.selectedIndex = val[2];
            this.getJcDetail(val);
        },
        // 从列表视图点击 获得工程监测配置详情
        getJcDetail(item) {
            this.selectedId = item[0];
            this.dialogData.title = `${this.ycysMc}-监测任务配置`;
            this.dialogData.dialog = true;
            this.$refs.dialog.getDetail(item);
        },
        // 关闭弹框时 清除选中
        closeDisesaseDialog() {
            this.selectedId = "";
        },
        async initMap(map) {
            this.basicMap = map;
            this.areaLayers = L.featureGroup().addTo(this.basicMap);
            await this.getBhgcList();
        },
        // 保存成功后 重新获得工程范围列表
        async saveSuccess() {
            await this.getBhgcList();
        },
    },
};
</script>
<style lang="scss" scoped>
</style>